import utils from 'rest/utils';
import urlBuilder from '../../requester/urlBuilder';
import createRequestParams from '../request/requestParamsFactory';
import { isEmpty } from 'lodash';
import contentTypeConfigurer from '../request/contentTypeConfigurer';
import batchRequestStrategy from './batchRequestStrategy';

export const batchRequestServiceFactory = (maxNoOfElementsInBatchRequest, batchEndpoints) => {
    return (fields, options, abortSignal) => {
        const queryParams = {},
            bodyParams = {};

        queryParams.key = options.key;

        const rawBatchItems = utils.required(options.batchItems);

        fields = contentTypeConfigurer.setField(fields);
        bodyParams.batchItems = rawBatchItems.map(function(item) {
            const requestParams = createRequestParams(fields, contentTypeConfigurer.setValue(item)),
                pathParams = requestParams.pathParams,
                queryParams = requestParams.queryParams,
                postParams = requestParams.postParams;

            const request = {
                query: urlBuilder(batchEndpoints.single, pathParams, queryParams)
            };

            if (!isEmpty(postParams)) {
                request.post = postParams;
            }

            return request;
        });

        const batchMode = options.batchMode ||
            (bodyParams.batchItems.length <= maxNoOfElementsInBatchRequest ? 'sync' : 'redirect');

        return batchRequestStrategy({
            batchMode,
            waitTimeSeconds: options.waitTimeSeconds,
            queryParams: queryParams,
            bodyParams: bodyParams,
            endpoints: {
                sync: batchEndpoints.batchSync,
                async: batchEndpoints.batch
            },
            abortSignal
        });
    };
};
