import parameterApplications from 'common/parameterApplications';

const fieldName = 'contentType';

export default {
    setField: (fields) => {
        const copiedFields = Object.assign({}, fields);
        copiedFields[fieldName] = {
            application: parameterApplications.PATH
        };
        return copiedFields;
    },

    setValue: (requestOptions) => {
        requestOptions[fieldName] = 'json';
        return requestOptions;
    }
};
