import 'core-js';
import serviceFactory from './services/serviceFactory';
import { singleRequestServiceFactory } from './rest/request/singleRequestServiceFactory';
import { batchRequestServiceFactory } from './rest/request/batchRequestServiceFactory';
import matrixFactory from './rest/request/matrixFactory';

export default {
    singleRequestServiceFactory,
    batchRequestServiceFactory,
    matrixFactory,
    serviceFactory
};
