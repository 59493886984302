import http from '@http';
import createRequestParams from '../request/requestParamsFactory';
import { isEmpty } from 'lodash';
import contentTypeConfigurer from '../request/contentTypeConfigurer';

export const singleRequestServiceFactory = (endpoint) => {
    return (fields, options, abortSignal) => {
        const requestParams = createRequestParams(contentTypeConfigurer.setField(fields),
                contentTypeConfigurer.setValue(options)),
            pathParams = requestParams.pathParams,
            queryParams = requestParams.queryParams;

        const httpOptions = {
            url: '{protocol}://' + endpoint,
            pathParameters: pathParams,
            queryParameters: queryParams,
            abortSignal
        };

        const bodyParams = requestParams.postParams;

        if (isEmpty(bodyParams)) {
            return http.get(httpOptions);
        } else {
            httpOptions.bodyParameters = bodyParams;
            return http.post(httpOptions);
        }
    };
};
